import React from "react";

function Footer() {
  return (
  <div className="footer">
    <p>Copyright ©{new Date().getFullYear()}, Richard Brower</p>
  </div>
  )
}

export default Footer;
